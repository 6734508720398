function AboutUsImage(props) {
    return (
        <div className="col-xl-6 col-lg-6 wow fadeInLeft" data-wow-delay="0.3s">
            <div className="fm-director-about-img">
                <img
                    alt="img not found"
                    srcSet={props.data.image}
                    src={props.data.image}
                    width="720"
                    height="764"
                    decoding="async"
                    data-nimg="1"
                    loading="lazy"
                    style={{ color: 'transparent', width: '100%', height: 'auto' }}
                />
            </div>
        </div>
    )
}

export default AboutUsImage;