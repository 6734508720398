import { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-scroll';
import { useGlobalContext } from "../core/global/context.global";

function HeaderLayout(props) {
    const { showNavbar, handleShowNavbar } = useGlobalContext();

    const [isScrolled, setIsScrolled] = useState(false);

    const toggleShowNavbar = () => {
        handleShowNavbar();
    }

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;
            const threshold = 100; // Adjust the threshold as needed

            // Check if the user has scrolled past the threshold
            setIsScrolled(scrollY > threshold);
        };

        // Add event listener for scroll
        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <nav className={`navbar ${isScrolled ? 'scrolled' : ''}`}>
            <div className="header-logo">
                <a href="/">
                    <img src={props.data.logo_image} alt="Logo" />
                </a>
            </div>
            <div className="menu-icon" onClick={toggleShowNavbar}>
                <FontAwesomeIcon className="hamburger-icon" icon={faBars} />
            </div>
            <div className={`nav-elements ${showNavbar && 'active'}`}>
                <ul>
                    <li>
                        <Link onClick={toggleShowNavbar} to="about" duration={400}>
                            About Us
                        </Link>
                    </li>
                    <li>
                        <Link onClick={toggleShowNavbar} to="explore" duration={400}>
                            Explore
                        </Link>
                    </li>
                    <li>
                        <Link onClick={toggleShowNavbar} to="upcomingShows" duration={400}>
                            Upcoming Shows
                        </Link>
                    </li>
                    <li>
                        <Link onClick={toggleShowNavbar} to="featuredShows" duration={400}>
                            Gallery
                        </Link>
                    </li>
                    <li>
                        <Link onClick={toggleShowNavbar} to="myTeam" duration={400}>
                            My Team
                        </Link>
                    </li>
                    <li>
                        <Link onClick={toggleShowNavbar} to="contactUs" duration={400}>
                            Contact Us
                        </Link>
                    </li>
                </ul>
            </div>
        </nav>
    );
}

export default HeaderLayout;