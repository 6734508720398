import AboutUsImage from "../components/about-us/about-us-image.component";
import AboutUsText from "../components/about-us/about-us-text.component";

function AboutUsMain(props) {
    const about_us_data = props.data;
    return (
        <div id="about" className="fm-about-area bg-common-black section-spacing fix">
            <div className="container">
                <div className="row align-items-center g-5 gy-50">
                    <AboutUsImage data={about_us_data} />
                    <AboutUsText  data={about_us_data} />
                </div>
            </div>
        </div>
    )
}

export default AboutUsMain;