import UpcomingShowCard from "../components/upcoming-shows/upcoming-show-card-image.component";

function UpcomingShowsMain(props) {
    const upcoming_shows_data = props.data;
    return (
        <div id="upcomingShows" className="bg-common-black section-spacing">
            <div className="container">
                <div className="row section-title-spacing wow fadeInUp" data-delay="0.3s">
                    <div className="col-12">
                        <div className="fm-director-sec-title text-center "><span className="section-subtitle title-anim">TAMSEEL EVENTS</span>
                            <div>
                                <h2 className="section-main-title text-white title-anim featured-work-title">Upcoming Shows</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                {upcoming_shows_data.map((obj, index) => (
                    <UpcomingShowCard key={index} image={obj.image} title={obj.title} writer_name={obj.story_writer_name} director_name={obj.director_writer_name} description={obj.description} running_time_note={obj.running_time_note} language={obj.language} cast={obj.cast} shows={obj.shows} tagline={obj.tagline} />
                ))}
            </div>
        </div>
    )
}

export default UpcomingShowsMain;