function AboutUsText(props) {
    return (
        <div className="col-xl-6 col-lg-6 wow fadeInRight" data-wow-delay="0.3s">
            <div className="fm-about-content-section">
                <span className="section-subtitle title-anim">DISCOVERING TAMSEEL</span>
                <h3 className="section-main-title text-white mb-25 title-anim">
                    THE ART OF EXPRESSION
                </h3>
                <p className="mb-20">{props.data.text}</p>
            </div>
        </div>
    )
}

export default AboutUsText;