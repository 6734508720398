import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";

function SubShows(props) {
    const obj = props.obj;
    const index = props.index;
    return (
        <div className='tickets-info-div'>
            <p>{obj.name}</p>
            <div>
                {obj.dates.map((date, i) => (
                    <p key={i}>{date} &nbsp;</p>
                ))}
                <br />
                <a style={{color: '#c33434'}} href={obj.map_link} target="_blank">Location <FontAwesomeIcon icon={faArrowUpRightFromSquare} /></a>
                <br />

            </div>
            <div className='tickets-info-div-buttons'>
                <button className='view-info-btn' data-toggle="modal" data-target={`#exampleModal${index}`}>View Info</button>
                {obj.ticket_link == undefined ? null : <a href={obj.ticket_link} className='buy-tickets-btn' target="_blank">Buy Tickets</a>}
            </div>

            <div className="modal modal-xl fade" id={`exampleModal${index}`} tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{obj.name} Show</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <img style={{width: "100%"}} src={obj.modal_image} alt="Modal Image" />
                        </div>
                        {/* <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary">Save changes</button>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );

}

export default SubShows;