function FeaturedShowCard(props) {
    return (
        <div className="fm-director-films-single text-center wow fadeInUp" data-wow-delay="0.4s">
                <div className="fm-director-films-img">
                    <img
                        alt="img not found"
                        srcSet={props.image}
                        src={props.image}
                        width="500"
                        height="500"
                        decoding="async"
                        data-nimg="1"
                        loading="lazy"
                        style={{ color: 'transparent', width: '100%', height: 'auto' }}
                    />
                </div>
            {/* <div className="fm-director-films-content">
                <span className="fm-director-films-subtitle">{props.type}</span>
                <h4 className="fm-director-films-title">{props.title}
                </h4>
            </div> */}
        </div>
    )
}

export default FeaturedShowCard;