import FeaturedShowCard from "../components/featured-shows/featured-show-card.component";

function FeaturedShowsMain(props) {
    const featured_shows_data = props.data;
    return (
        <div id="featuredShows" className="fm-director-films-area bg-common-black section-spacing2 fm-director-padding">
            <div className="container">
                <div className="row section-title-spacing wow fadeInUp" data-delay="0.3s">
                    <div className="col-12">
                        <div className="fm-director-sec-title text-center">
                            <span className="section-subtitle title-anim">TAMSEEL</span>
                            <div>
                                <h2 className="section-main-title text-white title-anim featured-work-title">Gallery</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="fm-director-wrapper">
                    {featured_shows_data.map((obj, index) => (
                        <FeaturedShowCard key={index} image={obj.image} type={obj.type} title={obj.title} />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default FeaturedShowsMain;