function ExploreMainImage(props) {
    return (
        // <div className="fm-director-service-bg" style={{ backgroundImage: `url(${props.image})` }}></div>
        <div className="col-xl-6 col-lg-6 wow fadeInLeft explore-img2">
        <img src={props.image} className="fm-director-service-bg2"/>

        </div>
    )
}

export default ExploreMainImage;