import ExploreCategoriesCard from "../components/explore/explore-category-card.component";
import ExploreMainImage from "../components/explore/explore-main-image.component";

function ExploringTamseelMain(props) {
    const categories = props.data.categories;
    return (
        // <div id="explore" className="fm-director-service-area soft-blackbg fix">
        //     <ExploreMainImage image={props.data.mainImage} />
        //     <div className="container">
        //         <div className="fm-director-service-wrapper section-spacing">
        //             <div className="row">
        //                 <div className="col-xl-7 col-lg-12">
        //                     <div className="row wow fadeInUp" data-wow-delay="0.3s">
        //                         <div className="col-12">
        //                             <div className="fm-director-service-title">
        //                                 <h2 className="section-main-title text-white title-anim mb-55"><span>Exploring</span><span style={{ color: '#e13b3b' }}> Tamseel</span></h2>
        //                             </div>
        //                         </div>
        //                     </div>
        //                     <div className="row g-5 gy-50">
        //                         {categories.map((obj, index) => (
        //                             <ExploreCategoriesCard key={index} image={obj.image} title={obj.title} text={obj.text} />
        //                         ))}
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>


        // 
        <div id="explore" className="soft-blackbg fix section-spacing">
            <div className="container">
                <div className="row section-title-spacing wow fadeInUp" data-delay="0.3s">
                    <div className="col-12">
                        <div className="fm-director-sec-title text-center">
                            <span className="section-subtitle title-anim">EXPLORING</span>
                            <div>
                                <h2 className="section-main-title text-white title-anim featured-work-title">TAMSEEL</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container exp">
                <div className="fm-director-service-wrapper col-xl-6 col-lg-12 wow fadeInRight">
                    <div className="row g-5 gy-50">
                        {categories.map((obj, index) => (
                            <ExploreCategoriesCard key={index} image={obj.image} title={obj.title} text={obj.text} />
                        ))}
                    </div>
                </div>
                <ExploreMainImage image={props.data.mainImage} />
            </div>
        </div>


    )
}

export default ExploringTamseelMain;