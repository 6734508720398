import AboutUsMain from "../main_components/about-us-main.component";
import data from "../core/data.json";
import ExploringTamseelMain from "../main_components/explore-main.component";
import FeaturedShowsMain from "../main_components/featured-shows-main.component";
import UpcomingShowsMain from "../main_components/upcoming-shows-main.component";
import MyTeamMain from "../main_components/my-team-main.component";
import ContactUsMain from "../main_components/contact-us-main.component";
import { useGlobalContext } from "../core/global/context.global";

function MainLayout() {
    const about_us_data = data.aboutUs;
    const explore_data = data.explore;
    const featured_shows_data = data.featuredShows;
    const upcoming_shows_data = data.upcomingShows;
    const team_member_data = data.myTeam;
    const contact_us_data = data.profile;

    const { showNavbar, handleShowNavbar } = useGlobalContext();

    const toggleShowNavbar = () => {
        if (window.innerWidth <= 916 && showNavbar === true) {
            handleShowNavbar();
        }
    }

    return (
        <div onClick={toggleShowNavbar}>
            < br />
            <br />
            <br />
            <AboutUsMain data={about_us_data} />
            <ExploringTamseelMain data={explore_data} />
            <UpcomingShowsMain data={upcoming_shows_data} />
            <FeaturedShowsMain data={featured_shows_data} />
            <MyTeamMain data={team_member_data} />
            <ContactUsMain data={contact_us_data} />
        </div>
    )
}

export default MainLayout;