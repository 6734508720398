import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";

import { useState, useEffect } from "react";

function ScrollToTop() {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;
            const scrollThreshold = 200; // Adjust the threshold as needed

            setIsVisible(scrollY > scrollThreshold);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScrollTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
            <button
                id="scroll_top"
                className={`scroll-top ${isVisible ? 'visible' : ''}`}
                style={{ display: isVisible ? 'block' : 'none' }}
                onClick={handleScrollTop}
            >
                <FontAwesomeIcon icon={faArrowUp} />
            </button>
    )
}

export default ScrollToTop;