import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import SubShows from './sub-shows.component';

function UpcomingShowCard(props) {
    const shows_data = props.shows;

    return (
        <div className="upcoming-shows-card">
            <div className="upcoming-shows-card-img">
                <img
                    alt="img not found"
                    src={props.image}
                    loading="lazy"
                />
            </div>
            <div className="upcoming-shows-card-right">
                <div className="upcoming-shows-card-inner">
                    <h1 className="upcoming-shows-card-inner-heading">{props.title}</h1>
                    <p>Written & Directed by: {props.writer_name}</p>
                    <p>Language: {props.language}</p>
                    <br />
                    <p>Synopsis: "{props.description}"</p>
                    <br />
                    <p>Cast & Crew: {props.cast}</p>
                    <p>Duration: {props.running_time_note}</p>
                    <div class="upcoming-show-div">
                        <div class="upcoming-show-tagline">{props.tagline}</div>
                    </div>
                    {/* <p>Date: <span className="upcoming-shows-card-right-date">{props.date}</span></p> */}
                    {/* <p className='upcoming-shows-card-date-main'>Date: <span className='upcoming-shows-card-right-date-main'>
                        {props.date.map((date, index) => (
                            <span key={index} className="upcoming-shows-card-right-date">{date}</span>
                        ))}
                    </span>
                    </p>
                    <p>Venue: <a target='_blank' href={props.map_location}>{props.venue}</a></p>
                    <br />
                    {props.ticket_link == undefined ? null : <a href={props.ticket_link} className='buy-tickets-btn' target="_blank">Buy Tickets</a>} */}

                    {/* Start */}
                    <div className='tickets-info-main'>
                        {shows_data.map((obj, index) => (
                            <SubShows key={index} index={index} obj={obj} />
                        ))}
                    </div>
                    {/* End */}
                </div>
            </div>

        </div>
    )
}

export default UpcomingShowCard;