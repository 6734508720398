import data from "../core/data.json";
import FooterLayout from "../layouts/footer.layout";
import HeaderLayout from "../layouts/header.layout";
import MainLayout from "../layouts/main.layout";
import ScrollToTop from "../components/scroll-top.component";

function HomePage() {
    const header_data = data.profile;
    const footer_data = data.profile;
    return (
        <div className="bg-common-black">
            <HeaderLayout data={header_data} />
            <MainLayout />
            <FooterLayout data={footer_data} />
            <ScrollToTop />
        </div>
    )
}

export default HomePage;