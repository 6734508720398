import { useFormik } from 'formik';
import * as Yup from 'yup';
import emailjs from '@emailjs/browser';
import React, { useState } from 'react';


function ContactUsForm(props) {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const formik = useFormik({
        initialValues: {
          name: '',
          email: '',
          subject: '',
          msg: '',
        },
        validationSchema: Yup.object({
          name: Yup.string().required('Required'),
          email: Yup.string().email('Invalid email address').required('Required'),
          subject: Yup.string().required('Required'),
          msg: Yup.string().required('Required'),
        }),
        // onSubmit: (values) => {
        //   // Handle form submission
        //   sendEmail(values);
        // },
        onSubmit: async (values, { resetForm }) => {
            setIsSubmitting(true);
      
            try {
              await sendEmail(values);
              // Clear form and set submitting to false
              resetForm();
            } catch (error) {
              console.error('Email sending failed:', error);
            } finally {
              setIsSubmitting(false);
            }
          },
      });
    
      const sendEmail = (values) => {
        emailjs.send(process.env.REACT_APP_EMAIL_SERVICE_ID, process.env.REACT_APP_EMAIL_TEMPLATE_ID, values, process.env.REACT_APP_EMAIL_PUBLIC_KEY)
          .then((response) => {
            console.log('Email sent successfully:', response);
          })
          .catch((error) => {
            console.error('Email sending failed:', error);
          });
      };
    
      return (
        <div className="col-xl-8 col-lg-8">
          <div className="fm-contact-info-form ml-50">
            <form id="contact-form" onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-xl-6 col-lg-6 contact-input-field">
                  <input
                    name="name"
                    type="text"
                    placeholder="Enter your name"
                    id="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className="error">{formik.errors.name}</div>
                  )}
                </div>
                <div className="col-xl-6 col-lg-6 contact-input-field">
                  <input
                    name="email"
                    type="email"
                    placeholder="Enter your email"
                    id="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className="error">{formik.errors.email}</div>
                  )}
                </div>
              </div>
              <div className="col-xl-12 contact-input-field">
                <input
                  type="text"
                  placeholder="Subject"
                  id="text"
                  name="subject"
                  value={formik.values.subject}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.subject && formik.errors.subject && (
                  <div className="error">{formik.errors.subject}</div>
                )}
              </div>
              <div className="col-xl-12 contact-input-field">
                <textarea
                  name="msg"
                  id="msg"
                  placeholder="Type your message..."
                  value={formik.values.msg}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                ></textarea>
                {formik.touched.msg && formik.errors.msg && (
                  <div className="error">{formik.errors.msg}</div>
                )}
              </div>
              <div className="col-xl-12">
                <button type="submit">Send Message</button>
              </div>
            </form>
          </div>
        </div>
      );
}

export default ContactUsForm;