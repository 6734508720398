import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faTwitter, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faPhone, faEnvelopeOpenText, faMapLocationDot } from "@fortawesome/free-solid-svg-icons";

const FooterLayout = (props) => {
    return (
        <footer>
            <div className="footer-area-5 section-spacing-top pos-rel">
                <div className="footer-bg-3" style={{ backgroundImage: 'url("/_next/static/media/img-1.6608414b.jpg")' }}></div>
                <div className="container">
                    <div className="footer-middle-area">
                        <div className="footer-wrapper footer-widget-about">
                            <div className="footer-logo-2">
                                <a href="/">
                                    <img alt="Logo" className='footer_logo_image' src={props.data.logo_image} />
                                </a>
                            </div>
                            {/* <div className="footer-text">
                                <p>{props.data.description}</p>
                            </div> */}
                        </div>
                        <div className="footer-wrapper footer-widget-social">
                            <div className="footer-title">
                                <h4>Follow Us</h4>
                            </div>
                            <div className="footer-info">
                                <p>Connect with me on social media</p>
                            </div>
                            <div className="fm-social-2 footer-social-3">
                                <a target="_blank" href={props.data.facebook_link}><FontAwesomeIcon icon={faFacebookF} /></a>
                                {/* <a target="_blank" href={props.data.twitter_link}><FontAwesomeIcon icon={faTwitter} /></a> */}
                                <a target="_blank" href={props.data.instagram_link}><FontAwesomeIcon icon={faInstagram} /></a>
                                {/* <a target="_blank" href={props.data.youtube_link}><FontAwesomeIcon icon={faYoutube} /></a> */}
                            </div>
                        </div>
                        <div className="footer-wrapper footer-widget-contact">
                            <div className="footer-title">
                                <h4>Contact Us</h4>
                            </div>
                            <ul className="footer-2-link">
                                {/* <li>
                                    <FontAwesomeIcon icon={faMapLocationDot} />
                                    <p>
                                        <a href={props.data.map_location}>
                                            {props.data.location}
                                        </a>
                                    </p>
                                </li> */}
                                <li>
                                    <FontAwesomeIcon icon={faPhone} />
                                    <p>
                                        {props.data.contact_number}
                                    </p>
                                </li>
                                <li>
                                    <FontAwesomeIcon icon={faEnvelopeOpenText} />
                                    <p>
                                        <a href={props.data.contact_email}>{props.data.contact_email}</a>
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="footer-bottom-4">
                        <div className="footer-bottom-wrapper-2 ">
                            <div className="footer-bottom-inner">
                                <div className="footer-copyright">
                                    <p>© 2023<a href="/"> Tamseel </a> All Rights Reserved.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default FooterLayout;
