function ExploreCategoriesCard(props) {
    return (
        <div className="col-xl-6 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
            <div className="fm-director-service-single fade_bottom">
                <img
                    alt="img not found"
                    srcSet={props.image}
                    src={props.image}
                    width="500"
                    height="500"
                    decoding="async"
                    data-nimg="1"
                    loading="lazy"
                    style={{ color: 'transparent', width: 'auto', height: 'auto' }}
                />
                <h4 className="fm-director-service-title">{props.title}</h4>
                <p>{props.text}</p>
            </div>
        </div>
    )
}

export default ExploreCategoriesCard;