import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelopeOpenText, faMapLocationDot } from "@fortawesome/free-solid-svg-icons";

function ContactUsDataDetails(props) {
    return (
        <div className="col-xl-4 col-lg-4">
            <div className="fm-contact-info-wrapper">
                <div className="fm-contact-info-single mb-40">
                    <span className="fm-contact-info-icon">
                        <FontAwesomeIcon icon={faPhone} />
                    </span>
                    <div className="fm-contact-info-text">
                        <h5 className="fm-contact-info-text-label">Phone</h5>
                        <p>
                            {props.contact_number}
                        </p>
                    </div>
                </div>
                <div className="fm-contact-info-single mb-40">
                    <span className="fm-contact-info-icon">
                        <FontAwesomeIcon icon={faEnvelopeOpenText} />
                    </span>
                    <div className="fm-contact-info-text">
                        <h5 className="fm-contact-info-text-label">Email</h5>
                        <p>
                            <a href={props.contact_email}>{props.contact_email}</a>
                        </p>
                    </div>
                </div>
                {/* <div className="fm-contact-info-single mb-40">
                    <span className="fm-contact-info-icon">
                        <FontAwesomeIcon icon={faMapLocationDot} />
                    </span>
                    <div className="fm-contact-info-text">
                        <h5 className="fm-contact-info-text-label">Location</h5>
                        <p>{props.location}</p>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default ContactUsDataDetails;