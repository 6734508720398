import ContactUsDataDetails from '../components/contact-us/contact-us-data-details.component';
import ContactUsForm from '../components/contact-us/contact-us-form.component';

const ContactUsMain = (props) => {
    const contact_us_data = props.data;
    return (
        <div id='contactUs' className=" soft-blackbg fix section-spacing">
            <div className="container">
                <div className="row section-title-spacing wow fadeInUp" data-delay="0.3s">
                    <div className="col-12">
                        <div className="fm-director-sec-title text-center "><span className="section-subtitle title-anim">CONTACT FORM</span>
                            <div>
                                <h2 className="section-main-title text-white title-anim featured-work-title">Contct Us</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row justify-content-center wow fadeInUp" data-wow-delay="0.3s">
                    <div className="col-xxl-10">
                        <div className="fm-contact-content-wrapper ">
                            <div className="row g-5">
                                <ContactUsDataDetails contact_number={contact_us_data.contact_number} contact_email={contact_us_data.contact_email} location={contact_us_data.location} />
                                <ContactUsForm />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactUsMain;
