import TeamMemberCard from "../components/my-team/teamMemberCard.component";

function MyTeamMain(props) {
    const team_members_data = props.data;
    return (
        <div id="myTeam" className="fm-director-team-area team__animation bg-common-black section-spacing2 fix">
            <div className="container">
                <div className="row wow fadeInUp" data-wow-delay="0.3s">
                    <div className="col-xl-12">
                        <div className="fm-director-team-title text-center">
                            <span className="section-subtitle title-anim">TEAM TAMSEEL</span>
                            <div>
                                <h2 className="section-main-title text-white title-anim team-name-clr">Meet My Team</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row g-5 gy-50">
                    {team_members_data.map((obj, index) => (
                        <TeamMemberCard key={index} image={obj.image} name={obj.name} description={obj.description} />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default MyTeamMain;