// import './App.css';
import './assets/css/style.css'
import HomePage from './views/home.view';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <HomePage />
      </header>
    </div>
  );
}

export default App;
