import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Helmet } from 'react-helmet';
import { GlobalProvider } from './core/global/context.global';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Helmet>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap"
      />
      <link href="https://fonts.cdnfonts.com/css/autography-2" rel="stylesheet" />
    </Helmet>
    <GlobalProvider>
      <App />
    </GlobalProvider>
  </React.StrictMode>
);