function TeamMemberCard(props) {
    const signature = props.name.split(" ")[0]
    return (
        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
            <div className="fm-director-team-single team__item">
                <a className="fm-director-team">
                    <img
                        alt="img not found"
                        src={props.image}
                        width="500"
                        height="500"
                        decoding="async"
                        data-nimg="1"
                        loading="lazy"
                        style={{ color: 'transparent', width: '100%', height: 'auto' }}
                    />
                    {/* <span className="fm-director-sign">{signature}</span> */}
                </a>
                <div className="fm-director-content">
                    <h4 className="fm-director-name">
                        <a className="team-name-clr" style={{ color: '#fff'}}>
                            {props.name}
                        </a>
                    </h4>
                    <span className="fm-director-designation">{props.description}</span>
                    <div className="fm-director-social social-hov-clr">
                        {/* Add social media links/icons */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TeamMemberCard;